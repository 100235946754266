import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import axios from "axios";

import { InputTextarea } from "primereact/inputtextarea";
import { Formik, Field } from "formik";

import { ProgressSpinner } from "primereact/progressspinner";
import { labels_dict } from "../utils/constants/mapping_dict";

const FeedbackForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    title: "",
    issue: "",
    issue_label: "",
    email: "",
  };

  const TopBar = () => {
    return (
      <div className="flex flex-row mx-auto w-full my-8 justify-center">
        <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
          Help Us Improve
        </h1>
      </div>
    );
  };

  const validate = (values) => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex = /^[a-zA-Z][^\s@]*@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.title) {
      errors.title = "Title is required";
    }
    if (values.email && !regex.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.issue) {
      errors.issue = "Feedback description is required";
    }

    if (!values.issue_label) {
      errors.issue_label = "Label is required";
    }

    return errors;
  };

  const submitForm = async (values) => {
    setLoading(true);
    // const issueBody = values.email
    //   ? `${values.issue}\n Email: ${values.email}`
    //   : values.issue;

    try {
      const issues = {
        title: values.title,
        body: values.issue,
        // body: issueBody,
        labels: [values.issue_label],
      };
      const issue_db = {
        title: values.title,
        body: values.issue,
        email: values.email,
        labels: [values.issue_label],
      };

      const res1 = await axios.post("api/feedback/submit_issue/", issue_db);

      // return;
      const res = await axios.post("api/feedback/git_issue/", issues);
      if (res.status === 201 && res1.status === 200) {
        alert("Issue created successfully");
        navigate("/datasets");
      } else {
        alert(`Error during form submission: ${res.status}`);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      if (error.response) {
        alert(`Error during form submission: ${error.response.status}`);
      } else if (error.request) {
        alert("No response received from server");
      } else {
        alert("Error setting up request");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="min-h-full md:min-h-screen items-center py-20 px-4 md:px-32">
        <TopBar />
        <div className="py-4 md:py-4 px-4 md:px-32 w-4/5 mx-auto">
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={submitForm}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2 after:content-['*'] after:ml-1 after:text-red-500">
                    Feedback type
                  </label>
                  <Field
                    name="issue_label"
                    as="select"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">Select feedback type</option>
                    {labels_dict.map((label_name) => (
                      <option key={label_name.code} value={label_name.code}>
                        {label_name.name}
                      </option>
                    ))}
                  </Field>
                  {errors.issue_label && touched.issue_label && (
                    <span className="error text-red-500">
                      {errors.issue_label}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2 after:content-['*'] after:ml-1 after:text-red-500">
                    Feedback title
                  </label>
                  <InputText
                    id="title"
                    type="text"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.title && touched.title && (
                    <span className="error text-red-500">{errors.title}</span>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Email
                  </label>
                  <p className="text-xs text-gray-400 mb-2">
                    If you want a follow up please provide the best email to
                    contact you
                  </p>
                  <InputText
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.email && (
                    <span className="error text-red-500">{errors.email}</span>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2 after:content-['*'] after:ml-1 after:text-red-500">
                    Description
                  </label>
                  <InputTextarea
                    id="issue"
                    type="text"
                    rows={4}
                    cols={30}
                    value={values.issue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                    placeholder="Ex: I could not download x dataset after having my application approved"
                  />
                  {errors.issue && touched.issue && (
                    <span className="error text-red-500">{errors.issue}</span>
                  )}
                </div>
                <div className="h-screen flex justify-content-center items-center">
                  {" "}
                  {loading && (
                    <ProgressSpinner
                      style={{ width: "250px", height: "250px" }}
                      strokeWidth="6"
                    />
                  )}
                </div>

                <div className="flex items-center justify-center mb-4">
                  <button
                    type="submit"
                    className={`bg-blue-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                      !(dirty && isValid) && "opacity-50 cursor-not-allowed"
                    }`}
                    disabled={!(dirty && isValid)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default FeedbackForm;
