import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <header
        className="h-screen w-full bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: "url('/images/backgrounds/resizedrocket.png')",
        }}
      >
        <div className="flex flex-col justify-center h-full px-4 text-center md:items-start md:text-left md:px-32">
          <h1 className="text-gray-200 text-4xl md:text-8xl font-bold">
            AIRCHECK
          </h1>
          <p className="text-gray-200 text-xl md:text-3xl mt-4">
            Artificial Intelligence-Ready CHEmiCal Knowledge base
          </p>
          <Link to="/datasets">
            <button className="mt-6 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300">
              Datasets
            </button>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Home;
