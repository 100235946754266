import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import BasicRoutes from "./Routes";
import Navbar from "./components/common/navbar/Navbar";
import { AuthProvider } from "./context/AuthContext";
import Footer from "./components/common/footer/Footer";
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Navbar />
        <BasicRoutes />
        <Footer />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
