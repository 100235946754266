import React from "react";
import { InputText } from "primereact/inputtext";
import { handlePasswordReset, openEmailLoginDialog } from "../auth/AuthActions";

const ResetPasswordDialogFooter = ({
  email,
  resetPassword,
  setNotificationMessage,
  setIsError,
  setShowNotification,
  setShowLoginDialog,
  setShowResetPasswordDialog,
  setShowLoginChoiceDialog,
  setShowSignUpDialog,
}) => {
  const handleSendClick = () => {
    handlePasswordReset(
      email,
      resetPassword,
      setNotificationMessage,
      setIsError,
      setShowNotification,
      setShowLoginDialog,
      setShowResetPasswordDialog
    );
  };

  const handleBackClick = () => {
    openEmailLoginDialog(setShowLoginChoiceDialog, setShowSignUpDialog, setShowLoginDialog, setShowResetPasswordDialog);
  };

  return (
    <div className="flex justify-between">
      <button
        className="bg-green-700 text-white py-2 px-4 rounded hover:bg-green-500 transition duration-300"
        onClick={handleSendClick}
      >
        Send
      </button>
      <button
        className="bg-red-700 text-white py-2 px-4 rounded hover:bg-red-500 transition duration-300"
        onClick={handleBackClick}
      >
        Back
      </button>
    </div>
  );
};

const ResetPasswordDialog = ({ email, setEmail }) => {
  return (
    <div className="p-field flex flex-col">
      <label htmlFor="email">Enter your email</label>
      <InputText className="border-2" id="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
    </div>
  );
};

export { ResetPasswordDialog, ResetPasswordDialogFooter };
