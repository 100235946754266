import React, { useState, useEffect } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";

const Datasets = React.memo(
  ({ hitgen, xchem }) => {
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {
      const getDatasets = async () => {
        try {
          const res = await axios.get("/api/data/dataset");
          setDatasets(res.data);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };
      getDatasets();
    }, []);

    const pivotedData = datasets.reduce(
      (acc, curr) => {
        if (curr.partner === "HitGen") {
          acc.hitGenTargets.push({ id: curr.id, target: curr.target });
        } else if (curr.partner === "X-Chem") {
          acc.xChemTargets.push({ id: curr.id, target: curr.target });
        }
        return acc;
      },
      { hitGenTargets: [], xChemTargets: [] }
    );

    const targetBodyHitgen = (rowData) => {
      return (
        <span className={`${hitgen ? "text-green-500 font-bold" : ""}`}>
          {rowData.target}
        </span>
      );
    };
    const targetBodyXchem = (rowData) => {
      return (
        <span className={`${xchem ? "text-blue-500 font-bold" : ""}`}>
          {rowData.target}
        </span>
      );
    };

    return (
      <>
        <div className="flex flex-row mb-4">
          <div className="flex-grow">
            <DataTable value={pivotedData.hitGenTargets}>
              <Column
                field="target"
                body={targetBodyHitgen}
                header={
                  <div className="flex items-center h-8">
                    <img
                      src="images/logos/hitgen-logo.png"
                      alt="HitGen logo"
                      className="w-8"
                    />
                    <h1 className="ml-1">HitGen Datasets</h1>
                  </div>
                }
                alignHeader={"center"}
              />
            </DataTable>
          </div>
          <div className="flex-grow">
            <DataTable value={pivotedData.xChemTargets}>
              <Column
                field="target"
                body={targetBodyXchem}
                header={
                  <div className="flex items-center h-8">
                    <img
                      src="images/logos/new-xchem-logo.png"
                      alt="X-Chem logo"
                      className="w-16"
                    />
                    <h1 className="ml-2">X-Chem Datasets</h1>
                  </div>
                }
                alignHeader={"center"}
              />
            </DataTable>
          </div>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => {
    // This function compares previous and next props
    // If hitgen and xchem props haven't changed, component won't re-render
    return (
      prevProps.hitgen === nextProps.hitgen &&
      prevProps.xchem === nextProps.xchem
    );
  }
);

export default Datasets;
