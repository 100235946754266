import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-700 via-blue-800 to-blue-900 text-white pt-3 pb-4 px-5 relative overflow-hidden">
      <div className="container mx-auto flex flex-col items-center">
        <a href="https://www.thesgc.org" target="_blank" rel="noopener noreferrer" className="group">
          <div className="transform group-hover:scale-110 transition duration-300 ease-in-out">
            <img src="/images/logos/sgc-logo.png" alt="SGC Logo" className="h-24" />
          </div>
        </a>
        <p className="text-sm leading-relaxed max-w-xl text-center mb-6">
          AIRCHECK is developed and populated as a collaboration between the Structural Genomics Consortium, University
          Health Network, University of Toronto, University of North Carolina, X-Chem, and HitGen.
        </p>
        {/* <hr className="border-t-2 border-blue-500 w-3/4 mx-auto mb-6" /> */}
        <div className="text-center text-xs mb-2">
          {/* <span className="block mb-2">
            © {new Date().getFullYear()} Structural Genomics Consortium. All rights reserved.
          </span> */}
        </div>
      </div>
      <div className="w-full h-4 bg-gradient-to-r from-transparent via-blue-600 to-transparent absolute bottom-0 left-0"></div>
    </footer>
  );
};

export default Footer;
