import { React, useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {
  ChoroplethController,
  ColorScale,
  ProjectionScale,
  GeoFeature,
} from "chartjs-chart-geo";
import { feature } from "topojson-client";

import { ProgressSpinner } from "primereact/progressspinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChoroplethController,
  ColorScale,
  ProjectionScale,
  GeoFeature
);

function Stats() {
  const [isLoading, setIsLoading] = useState(true);
  const [mapData, setMapData] = useState(null);
  const chartRef = useRef(null);

  const fetchMapData = async () => {
    try {
      const response = await fetch(
        "https://unpkg.com/world-atlas/countries-50m.json"
      );
      const topology = await response.json();

      const res = await axios.get(
        "api/application/organization/getUsersByCountry"
      );
      const data1 = res.data;

      const countries = feature(topology, topology.objects.countries).features;

      const chartData = {
        labels: countries.map((d) => d.properties.name),

        datasets: [
          {
            hoverBackgroundColor: "orange",
            borderColor: "grey",
            borderWidth: "1",
            label: "Countries",
            data: countries.map((d) => {
              const countryName = d.properties.name;
              const value = data1[countryName] || 0;
              return {
                feature: d,
                value: value,
              };
            }),
          },
        ],
      };

      setMapData(chartData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchMapData();
      handleIframeLoad();
    };

    fetchData();
  }, []);

  return (
    <div className="min-h-full md:min-h-screen items-center py-20 px-4 md:px-32">
      <div
        className="md:hidden bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
        role="alert"
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className="fill-current h-6 w-6 text-teal-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">
              View the Full Graph Plot On Large Screen
            </p>
            <p className="text-sm">
              For the best experience and to view the graph and plot clearly,
              please open this page in larger screen.
            </p>
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-col">
        {isLoading ? (
          <div className="absolute inset-0 flex justify-center items-center bg-white z-10">
            <ProgressSpinner
              style={{ width: "250px", height: "250px" }}
              strokeWidth="6"
            />
          </div>
        ) : (
          <>
            <div
              className="flex-grow flex flex-col overflow-hidden"
              style={{ height: "calc(100vh - 64px)" }}
            >
              <iframe
                className="absolute top-20 left-0 w-full h-full border-0"
                // className="w-full h-full border-0"
                src="http://34.130.28.56:8050/"
                // src={apiUrl}
                title="Stats"
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
            <div className="relative top-12 left-0 mb-24 h-full border-0">
              <div className="mt-20 text-center">
                <p className="m-10 font-extrabold text-xl text-gray-700">
                  Global Distribution of Dataset users by Country.
                </p>
              </div>
              {mapData && (
                <Chart
                  ref={chartRef}
                  type="choropleth"
                  data={mapData}
                  options={{
                    showOutline: true,
                    showGraticule: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            // Extract the country name and value
                            const countryName =
                              context.raw.feature.properties.name;
                            const value = context.raw.value;

                            return `${countryName}\n: ${value}`;
                          },
                        },
                      },
                    },
                    scales: {
                      projection: {
                        axis: "y",
                        projection: "equalEarth",
                      },
                      color: {
                        interpolate: (v) => {
                          if (v === 0) {
                            return "rgb(255, 255, 255)";
                          }
                          const r = 55;
                          const g = 149;
                          const b = 189;

                          // Calculate alpha based on the value
                          const alpha = Math.min(v, 1);

                          // Return color with calculated alpha
                          return `rgba(${r}, ${g}, ${b}, ${alpha})`;
                        },
                        axis: "y",

                        legend: {
                          position: "bottom-left",
                          align: "bottom",
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Stats;
