import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import IndividualApplication from "./IndividualApplication";
import MultiApplicants from "./MultipleApplicants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";

const fetchCountries = async () => {
  const response = await axios.get(
    "https://countriesnow.space/api/v0.1/countries/iso"
  );
  return response.data;
};

const fetchOrganizations = async () => {
  const response = await axios.get("api/application/organization/listall");

  return response.data;
};

const fetchUniversities = async () => {
  const response = await axios.get(
    "api/application/organization/listUniversities"
  );
  return response.data;
};
const Application = () => {
  const {
    data: orgNames,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["orgNameData"],
    queryFn: fetchOrganizations,
  });
  const create_org = [{ name: "Create New" }];
  const finalOrgList = [...(orgNames || []), ...create_org];

  const {
    data: uniNames,
    error: uniError,
    isLoading: loadingUniversities,
  } = useQuery({
    queryKey: ["uniNameData"],
    queryFn: fetchUniversities,
  });
  const create_uni = [{ name: "Create New" }];
  const finalUniList = [...(uniNames || []), ...create_uni];

  const {
    data: countriesName,
    error: countryNameError,
    isLoading: loadingCountries,
  } = useQuery({
    queryKey: ["countriesName"],
    queryFn: fetchCountries,
  });

  if (isLoading || loadingCountries || loadingUniversities) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner
          style={{ width: "250px", height: "250px" }}
          strokeWidth="6"
        />
      </div>
    );
  }

  if (error || countryNameError || uniError) {
    return (
      <div className="py-4 md:py-28 px-4 md:px-32 w-4/5 mx-auto text-red-500 font-bold p-4">
        There was an error! {error?.message || countryNameError?.message}
      </div>
    );
  }

  return (
    <div className="py-4 md:py-28 px-4 md:px-32 w-4/5 mx-auto">
      <h2 className="text-3xl text-center font-semibold mb-8 text-gray-700">
        Data Acess Registration Form
      </h2>
      <div className="flex flex-col items-center w-full">
        <TabView
          className="w-full"
          pt={{
            root: { className: "flex flex-col items-center w-full" },
            navContainer: { className: "w-full flex justify-center mb-4" },
            nav: { className: "flex flex-col sm:flex-row w-full sm:w-auto" },
            inkbar: { className: "hidden" },
          }}
        >
          <TabPanel
            header="Individual Application"
            leftIcon="pi pi-user mr-2"
            pt={{
              headerAction: {
                className: ({ context }) =>
                  context.selected
                    ? "px-6 py-3 border-b-2 border-blue-500 text-black font-semibold transition-colors"
                    : "px-6 py-3 border-b-2 border-transparent text-black hover:text-zinc-900 hover:border-blue-300 transition-colors",
              },
            }}
          >
            <div className="shadow-md border-gray-200 border-2 rounded px-8 pt-6 pb-8 mb-2 w-full">
              <IndividualApplication countriesName={countriesName} />
            </div>
          </TabPanel>
          <TabPanel
            header="Academic & Industry Application"
            rightIcon="pi pi-users ml-2"
            pt={{
              headerAction: {
                className: ({ context }) =>
                  context.selected
                    ? "px-6 py-3 border-b-2 border-blue-500 text-black font-semibold transition-colors"
                    : "px-6 py-3 border-b-2 border-transparent text-black hover:text-slate-950 hover:border-blue-300 transition-colors",
              },
            }}
          >
            <div className="shadow-md border-gray-200 border-2 rounded px-8 pt-6 pb-8 mb-2 w-full">
              <MultiApplicants
                finalOrgList={finalOrgList}
                countriesName={countriesName}
                finalUniList={finalUniList}
              />
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Application;

// npm install --save-dev @babel/plugin-proposal-private-property-in-object
