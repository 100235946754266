import React, { useState, useEffect } from "react";

import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { HitgenPDFLink, XchemPDFLink } from "../terms_and_conditions/ReadPdf";
import Datasets from "./datatable/DisplayTarget";
import { CustomDialog } from "../../components/common/dialog/CustomDialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const addGenericSubmission = async (formData) => {
  await delay(1000);
  const response = await axios.post("/api/application/submit", formData);
  return {
    status: response.status,
    data: response.data,
  };
};
const IndividualApplication = (countriesName) => {
  const { currentUser } = useAuth();

  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");

  const [orgAddress, setOrgAddress] = useState("");
  const [businessLine, setBusinessLine] = useState("");
  const [useDescription, setUseDescription] = useState("");

  const [dialogVisible, setDialogVisible] = useState(false);

  const users = [];

  const [openedEULAXchem, setOpenedEULAXchem] = useState(false);
  const [openedEULAHitgen, setOpenedEULAHitgen] = useState(false);
  const [hitgenAccepted, setHitgenAccepted] = useState(false);
  const [xchemAccepted, setXchemAccepted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countryOptions = countriesName?.countriesName?.data || [];
  const isAcademicApplication = false;
  const individualApplication = true;
  const orgName = "";

  const areFieldsEmpty = () => {
    if (
      !adminFirstName ||
      !adminLastName ||
      !businessLine ||
      !orgAddress ||
      !useDescription ||
      (!hitgenAccepted && !xchemAccepted)
    ) {
      return true;
    }
  };

  const genericMutation = useMutation({
    mutationFn: addGenericSubmission,
    onSuccess: (response) => {
      const { status, data } = response;
      if (status === 200) {
        Swal.fire({
          title: "Success!",
          text: data,
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/datasets"; // Navigate to the home page
          }
        });
      } else {
        setDialogVisible({
          visible: true,
          message: `${data}`,
          header: "Error",
        });
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });
  const { isPending } = genericMutation;

  const isError = genericMutation.isError;
  const error = genericMutation.error;
  useEffect(() => {
    if (isError && error) {
      handleError(error);
    }
  }, [isError, error]);
  const handleSubmit = async () => {
    //submitted form
    let orgCountry = selectedCountry.name;
    if (orgCountry === "United States") {
      orgCountry = "United States of America";
    }

    const formData = {
      application: {
        submitterEmail: currentUser.email,
        submittedInfo: {
          adminFirstName,
          adminLastName,
          orgName,
          orgCountry,
          orgAddress,
          businessLine,
          useDescription,
        },
        individualApplication,
        decision: {
          hitgenAccepted: null,
          xchemAccepted: null,
          hitgenDecisionDate: null,
          xchemDecisionDate: null,
        },
        users,
        termsAndConditions: {
          hitgenAccepted: hitgenAccepted,
          xchemAccepted: xchemAccepted,
        },
      },

      admin: {
        email: currentUser.email,
        firstName: adminFirstName,
        lastName: adminLastName,
        role: "Admin",
      },
      isAcademicApplication: {
        isAcademicApplication,
      },
    };
    // return;
    genericMutation.mutate(formData);
  };
  const handleError = (error) => {
    if (error.response) {
      setDialogVisible({
        visible: true,
        message: `${error.response.data}`,
        header: "Error",
      });
    } else if (error.request) {
      console.log("Error request:", error.request);
    } else {
      console.log("Error message:", error.message);
    }
    console.error("Error during form submission:", error);
  };

  if (isPending) {
    return (
      <div className="py-4 md:py-28 px-4 md:px-32 w-4/5 mx-auto">
        <div className="fixed inset-0 h-screen justify-center bg-white z-15">
          <div className="flex items-center justify-center">
            <ProgressSpinner
              style={{ margin: "100px", width: "250px", height: "250px" }}
              strokeWidth="6"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {dialogVisible.visible && (
        <CustomDialog
          dialogVisible={dialogVisible.visible}
          setDialogVisible={setDialogVisible}
          message={dialogVisible.message}
          header={dialogVisible.header}
        />
      )}

      <p className="text-sm font-bold text-gray-700 mb-2">
        Fields marked with
        <span className="after:content-['*'] after:ml-1 after:text-red-500 font-bold" />{" "}
        are mandatory
      </p>
      <div className="flex flex-row justify-center mb-4">
        <div className="mx-0">
          <label className="text-gray-700 text-sm">
            <p>
              By submitting this form, you are requesting access to the Datasets
              in your own capacity and not on behalf of an organization. Please
              note that if you check this box, you directly undertake the
              responsibilities of the “User” under the End User License
              Agreements below and no rights to receive or use the Datasets will
              be granted to any organizations or colleagues with whom you may
              work.
            </p>
          </label>
        </div>
      </div>

      <div className="mb-4">
        <label
          htmlFor="adminFirstName"
          className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
        >
          Your First Name
        </label>
        <InputText
          value={adminFirstName}
          onChange={(e) => setAdminFirstName(e.target.value)}
          id="adminFirstName"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="adminLastName"
          className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
        >
          Your Last Name
        </label>
        <InputText
          value={adminLastName}
          onChange={(e) => setAdminLastName(e.target.value)}
          id="adminLastName"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="orgAdd" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            Country
          </span>
        </label>

        <Dropdown
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.value)}
          options={countryOptions}
          optionLabel="name"
          placeholder="Select Country"
          id="orgAdd"
          filter
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="orgAddress" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            Address
          </span>
          <p className="text-xs text-gray-500">
            Please provide your address here
          </p>
        </label>
        <InputText
          value={orgAddress}
          onChange={(e) => setOrgAddress(e.target.value)}
          id="orgAddress"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="businessLine" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            Current Role
          </span>
          <p className="text-xs text-gray-500">
            provide a description of your current role(s) and qualifications
          </p>
        </label>
        <InputText
          value={businessLine}
          onChange={(e) => setBusinessLine(e.target.value)}
          id="businessLine"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="useDescription" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            “Proposed Use” of Datasets
          </span>
          <p className="text-xs text-gray-500">
            Please provide a brief, non-confidential, high-level description of
            the machine learning algorithm(s) or other research approach(es)
            that the User proposes to employ in analyzing the Datasets in
            sufficient detail to enable a person skilled in the relevant field
            to readily understand the User’s proposed use(s).
          </p>
        </label>
        <InputTextarea
          value={useDescription}
          onChange={(e) => setUseDescription(e.target.value)}
          id="useDescription"
          rows={6}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <p className="text-xs text-gray-700 before:content-['*'] before:mr-1">
          <span className="underline underline-offset-1 font-bold">
            For X-Chem Datasets only
          </span>
          : please note that the Proposed Use provided here, if accepted, will
          govern the scope of the authorized use(s) of the X-Chem Datasets under
          the End User License Agreement below, and such description must be
          updated if the User proposes to make uses of the Datasets not
          encompassed by the original description provided.
        </p>
      </div>

      <div className="card flex justify-content-center"></div>

      <div className="mb-8">
        <h1 className="font-extrabold text-gray-700"> HitGen Datasets</h1>
        <div className="flex flex-row justify-center mb-4">
          <div className="hitgen-tooltip">
            <input
              type="checkbox"
              onChange={() => setHitgenAccepted(!hitgenAccepted)}
              checked={hitgenAccepted}
              disabled={!openedEULAHitgen}
              className="disabled:opacity-50 disabled:cursor-not-allowed"
            />
            {!openedEULAHitgen && (
              <Tooltip
                target=".hitgen-tooltip"
                content="Review HitGen EULA before accepting"
                position="left"
              />
            )}
          </div>
          <div className="ml-3">
            <label className="text-gray-700 text-sm">
              <p>
                By clicking here, you hereby acknowledge having read and
                understood the{" "}
                <span onClick={() => setOpenedEULAHitgen(true)}>
                  <HitgenPDFLink />
                </span>{" "}
                and you hereby agree on behalf of the User that, if your
                registration is accepted, the User shall be bound by all of the
                terms and conditions thereof.
              </p>
            </label>
          </div>
        </div>
        <h1 className="font-extrabold text-gray-700 ml-1">X-Chem Datasets</h1>
        <div className="flex flex-row justify-center">
          <div className="xchem-tooltip">
            <input
              type="checkbox"
              onChange={() => setXchemAccepted(!xchemAccepted)}
              checked={xchemAccepted}
              disabled={!openedEULAXchem}
              className="disabled:opacity-50 disabled:cursor-not-allowed"
            />
            {!openedEULAXchem && (
              <Tooltip
                target=".xchem-tooltip"
                content="Review X-Chem EULA before accepting"
                position="left"
              />
            )}
          </div>
          <div className="ml-3">
            <label className="text-gray-700 text-sm">
              <p>
                By clicking here, you hereby acknowledge having read and
                understood the{" "}
                <span onClick={() => setOpenedEULAXchem(true)}>
                  <XchemPDFLink />
                </span>{" "}
                and you hereby agree on behalf of the User that, if your
                registration is accepted, the User shall be bound by all of the
                terms and conditions thereof.
              </p>
            </label>
          </div>
        </div>
      </div>

      <div className="flex-row">
        <Datasets hitgen={hitgenAccepted} xchem={xchemAccepted} />
      </div>

      <div className="flex items-center justify-center">
        <div className="tooltip-wrapper">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={areFieldsEmpty() || !currentUser}
            onClick={handleSubmit}
          >
            Submit
          </button>
          {(areFieldsEmpty() || !currentUser) && (
            <Tooltip
              target=".tooltip-wrapper"
              content="Verify you've completed all fields and you're logged in"
              position="top"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default IndividualApplication;
