import { getAuth, sendEmailVerification, signOut } from "firebase/auth";
import app from "../../../firebase";
const handleAuthAction = (currentUser, logOut, setShowLoginChoiceDialog) => {
  if (currentUser) {
    logOut();
    window.location.href = "/";
  } else {
    setShowLoginChoiceDialog(true);
  }
};

const auth = getAuth(app);
const handleGoogleLogin = (loginWithGoogle, setShowLoginChoiceDialog) => {
  loginWithGoogle();
  setShowLoginChoiceDialog(false);
};

const handleEmailPasswordLogin = async (
  email,
  password,
  loginWithEmailPassword,
  setNotificationMessage,
  setIsError,
  setShowNotification,
  setShowLoginDialog
) => {
  try {
    const { user } = await loginWithEmailPassword(email, password);

    if (!user.emailVerified) {
      setIsError(true);
      setNotificationMessage("Please check your email to verify your account. (Check your junk folder)");
    } else {
      setIsError(false);
      setNotificationMessage("Login Successful!");
      setShowLoginDialog(false);
    }
  } catch (error) {
    setIsError(true);
    setNotificationMessage(
      error.message === "Firebase: Error (auth/invalid-login-credentials)." ? "Invalid Credentials" : error.message
    );
  } finally {
    setShowNotification(true);
  }
};

const handlePasswordReset = async (
  email,
  resetPassword,
  setNotificationMessage,
  setIsError,
  setShowNotification,
  setShowLoginDialog,
  setShowResetPasswordDialog
) => {
  try {
    await resetPassword(email);
    setNotificationMessage(
      "If an account exists with this email, a password reset link has been sent. Please check your inbox and spam folder."
    );
    setIsError(false);
    setShowLoginDialog(true);
    setShowResetPasswordDialog(false);
  } catch (error) {
    setNotificationMessage(error.message);
    setIsError(true);
  }
  setShowNotification(true);
};

const handleEmailPasswordSignUp = async (
  name,
  email,
  password,
  signUpWithEmailPassword,
  setShowSignUpDialog,
  setNotificationMessage,
  setShowNotification,
  setIsError
) => {
  try {
    const userCredential = await signUpWithEmailPassword(email, password, name);

    try {
      const { emailVerified } = userCredential.user;
      await sendEmailVerification(userCredential.user);

      if (!emailVerified) {
        await signOut(auth);
      }

      setNotificationMessage(
        emailVerified
          ? "Signup successful! Please check your email to verify your account. (Check your junk folder)"
          : "Please check your email to verify your account. (Check your junk folder)"
      );
      setIsError(false);
    } catch (verificationError) {
      console.error("Error sending verification email: ", verificationError);
      setNotificationMessage(
        "Signup successful, but we could not send a verification email. Please check your email address."
      );
      setIsError(true);
    }
  } catch (error) {
    console.error("Error during email/password sign up: ", error);
    setNotificationMessage(error.message);
    setIsError(true);
  }

  setShowSignUpDialog(false);
  setShowNotification(true);
};

const openEmailLoginDialog = (
  setShowLoginChoiceDialog,
  setShowSignUpDialog,
  setShowLoginDialog,
  setShowResetPasswordDialog
) => {
  setShowLoginChoiceDialog(false);
  setShowSignUpDialog(false);
  setShowResetPasswordDialog(false);
  setShowLoginDialog(true);
};

const openEmailSignUpDialog = (setShowLoginDialog, setShowSignUpDialog) => {
  setShowLoginDialog(false);
  setShowSignUpDialog(true);
};

const openResetPasswordDialog = (setShowResetPasswordDialog, setShowSignUpDialog, setShowLoginDialog) => {
  setShowResetPasswordDialog(true);
  setShowSignUpDialog(false);
  setShowLoginDialog(false);
};

export {
  handleAuthAction,
  handleGoogleLogin,
  handleEmailPasswordLogin,
  handleEmailPasswordSignUp,
  handlePasswordReset,
  openEmailLoginDialog,
  openEmailSignUpDialog,
  openResetPasswordDialog,
};
