import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { HitgenPDFLink, XchemPDFLink } from "../terms_and_conditions/ReadPdf";
import axios from "axios";

const VersionDialog = (props) => {
  const { header, visible, maximizable, style, onHide, rowData } = props;

  const [selectedOption, setSelectedOption] = useState("Version 1");
  const [selectedRowData, setSelectedRowData] = useState(null);

  // const selectedRowData = rowData;
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  if (!rowData?.version_info) {
    return (
      <Dialog
        header={header}
        visible={visible}
        maximizable={maximizable}
        style={style}
        onHide={onHide}
      >
        <div>No version information available.</div>
      </Dialog>
    );
  }

  const getVersionNumbers = (data) => {
    const versions = [];
    if (data && data.version_info) {
      data.version_info.forEach((info) => {
        info.versions.forEach((version) => {
          versions.push(`Version ${version.version}`);
        });
      });
    }
    return versions;
  };
  const versionLabel = getVersionNumbers(rowData);

  const selectedVersion_lst = rowData.version_info;

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const getSelectedInfo = () => {
    // return final.find((version) => version.version_number === selectedOption);
    const selectedVersion = selectedVersion_lst
      .flatMap((info) => info.versions)
      .find((version) => `Version ${version.version}` === selectedOption);
    return selectedVersion;
  };

  const selectedInfo = getSelectedInfo();

  const handleYesClick = (rowData) => {
    setVisibleConfirm(false); // Close the dialog box
    if (rowData) {
      handleDownload(rowData.link, rowData.partner); // Call handleDownload with current row data
    }
  };

  const handleDownload = async () => {
    try {
      const datasetId = selectedInfo.path;
      const partner = rowData.partner;
      if (partner === "External") {
        window.open(datasetId, "_blank");
        return;
      }

      const response = await axios.get(`/api/gcp/getsignedurl`, {
        params: {
          datasetId,
          partner,
        },
      });

      // Use the signed URL for download
      const signedUrl = response.data.signedUrl;

      window.location.assign(signedUrl);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 429) {
          console.error(
            "Too many requests, please try again after 12 AM EST.",
            error.response.data.message
          );
          const message =
            error.response.data.message ||
            "Too many requests, please try again later.";
          alert(message);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        alert("No response from the server. Please try again later.");
      } else {
        alert("Error fetching download link.");
      }
    }
  };

  return (
    <>
      <Dialog
        visible={visibleConfirm}
        header="Download Dataset"
        style={{ width: "700px" }}
        onHide={() => setVisibleConfirm(false)}
        footer={
          <div>
            <Button
              label="No"
              onClick={() => setVisibleConfirm(false)}
              className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
            />
            <Button
              className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-green-500 text-base text-white hover:bg-green-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              label="Yes"
              onClick={() => handleYesClick(selectedRowData)}
            />
          </div>
        }
      >
        {selectedRowData && (
          <p className="m-0">
            {selectedRowData.partner === "HitGen" ? (
              <>
                {
                  " By clicking 'Yes', you acknowledge and agree that your access to and use of this Dataset is subject to the terms and condition of the "
                }
                <HitgenPDFLink />
                {
                  " , including a strict prohibition on reverse engineering the identities of structures or other attributes of the DNA-encoded libraries from which the Dataset is derived"
                }
              </>
            ) : selectedRowData.partner === "X-Chem" ? (
              <>
                {
                  " By clicking 'Yes', you acknowledge and agree that your access to and use of this Dataset is subject to the terms and condition of the "
                }
                <XchemPDFLink />
                {
                  " , including a strict prohibition on reverse engineering the identities of structures or other attributes of the DNA-encoded libraries from which the Dataset is derived"
                }
              </>
            ) : selectedRowData.partner === "External" ? (
              <>
                {
                  "By clicking 'Yes,' you agree to be redirected to the dataset hosted on Kaggle. Please note that this dataset is not an asset of the AIRCHECK project, and you must comply with the terms and conditions set by the competition"
                }
              </>
            ) : selectedRowData.partner === "SGC" ? (
              <>
                By Clicking <strong>Yes</strong>, you agree to use data under
                the terms of the{" "}
                <a
                  href="https://creativecommons.org/licenses/by/4.0/deed.en"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  Creative Commons Attribution 4.0 International License (CC BY
                  4.0)
                </a>{" "}
              </>
            ) : null}
          </p>
        )}
      </Dialog>
      <Dialog
        header={header}
        visible={visible}
        maximizable={maximizable}
        style={style}
        onHide={onHide}
      >
        <div className="flow-root">
          <div className="flex items-center">
            <div className="flex space-x-10">
              <DropdownList
                options={versionLabel.map((label) => ({
                  label,
                  value: label,
                }))}
                selectedValue={selectedOption}
                onChange={handleOptionChange}
                className="relative mt-2 bg-white border border-gray-300 z-50
            md:static md:mt-0"
              />

              <button
                className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100"
                // onClick={() => handleDownload()}
                onClick={() => {
                  setSelectedRowData(rowData);
                  setVisibleConfirm(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex flex-col">
            <div>
              {rowData?.target && (
                <>
                  <b>Target Name: </b>
                  {rowData.target}
                </>
              )}
            </div>
            <div>
              {rowData?.partner && (
                <>
                  <b>Created by: </b>
                  {rowData.partner}
                </>
              )}
            </div>
            <div>
              {selectedInfo?.selection_time && (
                <>
                  <b>Selection Date: </b> {selectedInfo.selection_time}
                </>
              )}
            </div>
            <div>
              {selectedInfo?.update_time && (
                <>
                  <b>Updated Date: </b> {selectedInfo.updated_time}
                </>
              )}
            </div>
            <div className="mt-2">
              {selectedInfo?.description && (
                <>
                  <b className="text-xl">Release Notes: </b>{" "}
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    {selectedInfo.description}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const DropdownList = ({ options, selectedValue, onChange }) => (
  <select
    className="border rounded px-1 py-3 font-bold rounded"
    value={selectedValue}
    onChange={onChange}
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

export default VersionDialog;
