import React from "react";
import { InputText } from "primereact/inputtext";
import { handleEmailPasswordSignUp, openEmailLoginDialog } from "../auth/AuthActions";

const SignUpDialog = ({ name, setName, email, setEmail, password, setPassword }) => {
  return (
    <>
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="name">Name</label>
          <InputText
            className="border-2"
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="email">Email</label>
          <InputText
            className="border-2"
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="password">Password</label>
          <InputText
            className="border-2"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

const SignUpDialogFooter = ({
  name,
  email,
  password,
  signUpWithEmailPassword,
  setShowSignUpDialog,
  setNotificationMessage,
  setShowNotification,
  setIsError,
  setShowLoginChoiceDialog,
  setShowLoginDialog,
  setShowResetPasswordDialog,
}) => {
  return (
    <div className="flex justify-between">
      <button
        className="bg-green-700 text-white py-2 px-4 rounded hover:bg-green-500 transition duration-300"
        onClick={() =>
          handleEmailPasswordSignUp(
            name,
            email,
            password,
            signUpWithEmailPassword,
            setShowSignUpDialog,
            setNotificationMessage,
            setShowNotification,
            setIsError
          )
        }
      >
        Sign Up
      </button>
      <button
        className=" bg-red-700 text-white py-2 px-4 rounded hover:bg-red-500 transition duration-300"
        onClick={() =>
          openEmailLoginDialog(
            setShowLoginChoiceDialog,
            setShowSignUpDialog,
            setShowLoginDialog,
            setShowResetPasswordDialog
          )
        }
      >
        Back
      </button>
    </div>
  );
};

export { SignUpDialog, SignUpDialogFooter };
