import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  onAuthStateChanged,
} from "firebase/auth";
import app from "../firebase";

// Firebase auth init
const auth = getAuth(app);
const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

let logoutTimer;

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [lastLoginTime, setLastLoginTime] = useState(null);
  const [loading, setLoading] = useState(true); //loading state to make sure the Google account has been retrieved

  const loginWithGoogle = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      const user = result.user;
      setCurrentUser(user);
      setLastLoginTime(Date.now());
    } catch (error) {
      console.error("Error during login with Google: ", error);
    } finally {
      setLoading(false);
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      clearTimeout(logoutTimer);
      setCurrentUser(null);
    } catch (error) {
      console.error("Logout error: ", error);
    }
  };

  const loginWithEmailPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user.emailVerified) {
        setCurrentUser(userCredential.user);
        setLastLoginTime(Date.now());
        return userCredential;
      } else {
        logOut();
        return userCredential;
      }
    } catch (error) {
      console.error("Error during email/password login: ", error);
      throw error;
    }
  };

  const checkForAutoSignOut = () => {
    //If 24 hours has passed since last login, logout the user
    if (lastLoginTime && Date.now() - lastLoginTime >= 1000 * 60 * 60 * 24) {
      logOut();
    }
  };

  const signUpWithEmailPassword = async (email, password, name) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (userCredential.user.emailVerified) {
        await updateProfile(userCredential.user, {
          displayName: name,
        });
        setCurrentUser(userCredential.user);
        return userCredential;
      } else {
        return userCredential;
      }
    } catch (error) {
      console.error("Error during email/password sign up: ", error);
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("Error resetting password: ", error);
      throw error;
    }
  };

  // Use useEffect for setting and cleaning up event listeners
  useEffect(() => {
    const handleUserActivity = () => {
      checkForAutoSignOut(); // Check if 24 hours have passed since last login
    };

    document.addEventListener("click", handleUserActivity);
    document.addEventListener("keypress", handleUserActivity);

    // the following runs before currentUser is mounted again to clean up old listeners
    return () => {
      document.removeEventListener("click", handleUserActivity);
      document.removeEventListener("keypress", handleUserActivity);
    };
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false); // Stop loading when user state is confirmed
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loginWithGoogle,
        loginWithEmailPassword,
        signUpWithEmailPassword,
        logOut,
        resetPassword,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
