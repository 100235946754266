import { React, useEffect, useState } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ApplicationInformation from "./ApplicationInformation";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import { ProgressSpinner } from "primereact/progressspinner";

const SingleApplication = () => {
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [admin, setAdmin] = useState("");
  const { currentUser } = useAuth();
  const [rejectJustification, setJustification] = useState("");

  const TopBar = ({ name }) => {
    return (
      <div className="flex flex-row mx-auto w-full my-8 justify-center">
        <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
          {name} Application
        </h1>
      </div>
    );
  };

  const handleDecision = async (decision, name) => {
    if (!decision) {
      if (rejectJustification === null) {
        alert(
          "Please explain the reason for rejection before rejecting application"
        );
        return;
      }
    }
    const timestamp = new Date().toISOString();
    const ipResponse = await axios.get("https://api.ipify.org?format=json");
    const userIp = ipResponse.data.ip;
    const decisionStatus = decision ? "Accepted" : "Rejected";
    const decisionDate = timestamp;
    const adminEmail = currentUser.email;
    const applicantName = name;
    const decisionMessage = `Decision made on ${timestamp}: Application ${decisionStatus} by ${currentUser.email}. IP address: ${userIp}.`;
    let body = {};
    if (admin.hitgenAdmin) {
      body = {
        hitgenDecision: decision,
        applicationId,
      };
    } else if (admin.xchemAdmin) {
      body = {
        xchemDecision: decision,
        applicationId,
        decisionMessage,
        rejectJustification,
        decisionStatus,
        decisionDate,
        adminEmail,
        applicantName,
      };
    } else {
      return;
    }
    try {
      await axios.put("/api/application/decision", body);
      Swal.fire({
        title: "Success!",
        text: `Application successfully ${decision ? "accepted" : "rejected"}`,
        icon: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          confirmButton:
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/application-list"; // Navigate to the home page
        }
      });
      // alert(`Application successfully ${decision ? "accepted" : "rejected"}`);
      // navigate("/application-list");
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  useEffect(() => {
    const getSingleApplication = async () => {
      if (!applicationId) {
        console.error("Application ID is undefined.");
        return;
      }
      try {
        const res = await axios.get(`/api/application/id/${applicationId}`);
        setApplication(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    getSingleApplication();
  }, [applicationId]);

  useEffect(() => {
    const getAdmin = async () => {
      try {
        const res = await axios.get(`/api/data/admin/${currentUser.email}`);
        setAdmin(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAdmin();
  }, [currentUser]);

  if (!application) {
    // Could add a loading spinner
    return (
      <div>
        <div className="py-4 md:py-28 h-96 px-4 md:px-32 w-4/5 mx-auto">
          <div className="fixed inset-0 h-96 justify-center bg-white z-15">
            <div className="flex items-center justify-center">
              <ProgressSpinner
                style={{ margin: "100px", width: "250px", height: "250px" }}
                strokeWidth="6"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    application && (
      <div className="items-center py-20 px-4 md:px-32">
        <div className="flex justify-between items-center">
          <Link
            to="/application-list"
            className="text-blue-500 hover:text-blue-700 p-2 hover:scale-105 transition duration-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </Link>

          <TopBar name={application.submittedInfo?.orgName} />
        </div>
        <ApplicationInformation
          application={application}
          applicationId={"AdminPortal"}
          adminInformation={admin}
          rejectJustification={rejectJustification}
          setJustification={setJustification}
        />
        <div className="flex items-center justify-between">
          <div className="tooltip-wrapper">
            {/* { application.decision.hitgenAccepted || application.decision.xchemAccepted} */}
            <Button
              label="Accept"
              className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={() =>
                handleDecision(
                  true,
                  (application.submittedInfo?.orgName ??
                    application.submitterEmail) ||
                    application.submitterEmail
                )
              }
            />
            <Button
              disabled={!rejectJustification}
              label="Reject"
              className={`bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2 ${
                !rejectJustification ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() =>
                handleDecision(
                  false,
                  (application.submittedInfo?.orgName ??
                    application.submitterEmail) ||
                    application.submitterEmail
                )
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default SingleApplication;
