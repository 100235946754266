import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { ml_models } from "../utils/constants/mapping_dict";
import DynamicHeightComponent from "../utils/constants/DynamicComponent";

const TopBar = () => {
  return (
    <div className="flex flex-row mx-auto w-full my-8 justify-center">
      <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
        ML-Models
      </h1>
    </div>
  );
};

const DisplayModels = () => {
  const [models, setModels] = useState([]);

  useEffect(() => {
    setModels(ml_models.models);
  }, []);

  const renderLink = (rowData) => {
    return (
      <a href={rowData.link} target="_blank" rel="noopener noreferrer">
        View Model
      </a>
    );
  };

  return (
    <DynamicHeightComponent>
      {" "}
      <TopBar />
      <div className="card">
        <DataTable
          value={models}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
        >
          <Column
            field="name"
            header="Name"
            alignHeader={"center"}
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="description"
            header="Description"
            alignHeader={"center"}
            style={{ width: "50%" }}
          ></Column>
          <Column
            field="link"
            header="Code Repo"
            body={renderLink}
            alignHeader={"center"}
            style={{ width: "30%" }}
          ></Column>
        </DataTable>
      </div>
    </DynamicHeightComponent>
  );
};

export default DisplayModels;
