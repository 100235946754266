import { React, useState } from "react";
import { doc_links } from "../../../utils/constants/mapping_dict";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Dropdown = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setOpen(!open);
    navigate("/readme");
  };
  const toggleDropdown_off = () => {
    setOpen(!open);
  };

  return (
    <div className="relative" data-open={open}>
      <button
        onClick={() => toggleDropdown()}
        className="text-blue-200 hover:bg-blue-600 hover:text-white transition duration-300 px-3 py-2 rounded-md"
      >
        <span>Documents</span>
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform ${
            open ? "rotate-180" : "rotate-0"
          } md:-mt-1`}
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {open && (
        <div className="absolute px-0 z-10 bg-blue-700 flex flex-col w-full rounded-md shadow-lg">
          {Object.entries(doc_links).map(([label, href_link]) => (
            <ScrollLink
              onClick={() => toggleDropdown_off()}
              key={label}
              to={label}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className={`text-blue-200 hover:text-white hover:bg-blue-600 transition duration-300 px-3 py-2 rounded-md text-medium text-sm m-1 hover:cursor-pointer`}
            >
              {label}
            </ScrollLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
