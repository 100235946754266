import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ExternalRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href =
      "https://web-app-153945772792.northamerica-northeast2.run.app/";
  }, [navigate]);

  return null; // Return null as this component only handles redirection
}

export default ExternalRedirect;
