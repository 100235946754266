import React from "react";

const HitgenPDFLink = () => {
  const openPDF = () => {
    const pdfURL = `/docs/HitGen.pdf`;
    window.open(pdfURL, "_blank");
  };

  return (
    <>
      <span
        onClick={openPDF}
        className="text-blue-600 text-medium cursor-pointer font-bold mt-2"
      >
        HitGen End User License Agreement
      </span>
    </>
  );
};

const XchemPDFLink = () => {
  const openPDF = () => {
    const pdfURL = `/docs/X-Chem.pdf`;
    window.open(pdfURL, "_blank");
  };

  return (
    <>
      <span
        onClick={openPDF}
        className="text-blue-600 text-medium cursor-pointer font-bold mt-2"
      >
        X-Chem End User License Agreement
      </span>
    </>
  );
};

export { HitgenPDFLink, XchemPDFLink };
