import React from "react";
import { InputText } from "primereact/inputtext";
import {
  handleGoogleLogin,
  openEmailLoginDialog,
  openEmailSignUpDialog,
  openResetPasswordDialog,
  handleEmailPasswordLogin,
} from "../auth/AuthActions";

const LoginChoiceDialog = ({
  loginWithGoogle,
  setShowLoginChoiceDialog,
  setShowSignUpDialog,
  setShowLoginDialog,
  setShowResetPasswordDialog,
}) => {
  return (
    <>
      <div className="flex justify-center my-8">
        <button
          className="flex flex-col justify-center items-center mx-4"
          onClick={() => handleGoogleLogin(loginWithGoogle, setShowLoginChoiceDialog)}
        >
          <img className={`w-14`} src="images/icons/google.png" alt="Google Icon" />
          <span className={`text-sm `}>Google Login</span>
        </button>
        <button
          className="flex flex-col justify-center items-center mx-4"
          onClick={() =>
            openEmailLoginDialog(
              setShowLoginChoiceDialog,
              setShowSignUpDialog,
              setShowLoginDialog,
              setShowResetPasswordDialog
            )
          }
        >
          <img className="w-14" src="images/icons/email.png" alt="Email Icon" />
          <span className={`text-sm`}>Email Login</span>
        </button>
      </div>
    </>
  );
};

const LoginDialog = ({ email, setEmail, password, setPassword }) => {
  return (
    <>
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="email">Email</label>
          <InputText
            className="border-2"
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="password">Password</label>
          <InputText
            className="border-2"
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

const LoginDialogFooter = ({
  email,
  password,
  loginWithEmailPassword,
  setNotificationMessage,
  setIsError,
  setShowNotification,
  setShowResetPasswordDialog,
  setShowLoginDialog,
  setShowSignUpDialog,
}) => {
  return (
    <div>
      <div className="flex justify-between text-left">
        <button
          className="bg-green-700 text-white py-2 px-4 rounded hover:bg-green-500 transition duration-300"
          onClick={() =>
            handleEmailPasswordLogin(
              email,
              password,
              loginWithEmailPassword,
              setNotificationMessage,
              setIsError,
              setShowNotification,
              setShowLoginDialog
            )
          }
        >
          Login
        </button>
        <button
          className="bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300 ml-3"
          onClick={() => openEmailSignUpDialog(setShowLoginDialog, setShowSignUpDialog, setShowLoginDialog)}
        >
          Sign Up
        </button>
      </div>
      <div className="text-left">
        <button
          className=" text-blue-700 hover:text-blue-500 transition duration-300 text-xs"
          onClick={() => openResetPasswordDialog(setShowResetPasswordDialog, setShowSignUpDialog, setShowLoginDialog)}
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export { LoginChoiceDialog, LoginDialog, LoginDialogFooter };
