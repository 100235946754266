import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Button } from "primereact/button";
import { HitgenPDFLink, XchemPDFLink } from "../terms_and_conditions/ReadPdf";
import { AsmsTable } from "./AsmsDatasets";
import { TabMenu } from "primereact/tabmenu";
import { ProgressSpinner } from "primereact/progressspinner";
import VersionDialog from "./VersionDetails";
const TopBar = () => {
  return (
    <div className="flex flex-row mx-auto w-full my-8 justify-center">
      <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">Datasets</h1>
    </div>
  );
};

const renderDictItem = ({ name, entry }) => {
  return (
    <ul
      className="p-2 sm:p-3 flex items-center text-gray-800 border border-gray-400"
      key={name}
    >
      <li className="w-full text-left ml-2">{name}</li>
      <li className="w-full text-left">{entry.mode}</li>
      <li className="w-full text-left">{entry.type}</li>
      <li className="w-full text-left ">{entry.description}</li>
    </ul>
  );
};

const Table = ({ datasets, dict }) => {
  const [dictDialogInfo, setDictDialogInfo] = useState({
    visible: false,
    partner: null,
  });
  const [applicationId, setApplicationId] = useState(null);
  const [approvedDownload, setApprovedDownload] = useState({
    hitgen: false,
    hitgenTerms: false,
    xchem: false,
    xchemTerms: false,
    preApproved: false,
  });
  const { currentUser, loading } = useAuth();
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [isAgreed, setAgreeTerms] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [historyDialog, setHistoryDialog] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const approvalCheck = async () => {
      setLoader(true);
      try {
        const res = await axios.get(
          `/api/data/getwhitelisted/${currentUser.email}`
        );

        setLoader(false);
        setApprovedDownload({
          hitgen: res.data.hitgenAccepted,
          hitgenTerms: res.data.hitgenTerms,
          xchem: res.data.xchemAccepted,
          xchemTerms: res.data.xchemTerms,
          preApproved: res.data.preApproved,
        });
      } catch (error) {
        setLoader(false);
        console.error("Error fetching error: ", error);
      }
    };
    const getApplication = async () => {
      try {
        const res = await axios.get(
          `/api/application/email/${currentUser.email}`
        );
        setApplicationId(res.data.applicationId);
      } catch (error) {
        console.error("Error fetching error: ", error);
      }
    };

    if (!loading && currentUser) {
      approvalCheck();
      getApplication();
    }
  }, [currentUser, loading]);

  const handleDownload = async (datasetId, partner) => {
    try {
      setAgreeTerms(false);
      if (partner === "External") {
        window.open(datasetId, "_blank");
        return;
      }

      const response = await axios.get(`/api/gcp/getsignedurl`, {
        params: {
          datasetId,
          partner,
        },
      });

      // Use the signed URL for download
      const signedUrl = response.data.signedUrl;

      window.location.assign(signedUrl);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 429) {
          console.error(
            "Too many requests, please try again after 12 AM EST.",
            error.response.data.message
          );
          const message =
            error.response.data.message ||
            "Too many requests, please try again later.";
          alert(message);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        alert("No response from the server. Please try again later.");
      } else {
        alert("Error fetching download link.");
      }
    }
  };

  const renderDataDictionaryButton = (rowData) => {
    return (
      <button
        className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100"
        onClick={() =>
          setDictDialogInfo({ visible: true, partner: rowData.partner })
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
      </button>
    );
  };

  const handleYesClick = (rowData) => {
    setAgreeTerms(true); // Update isAgreed state to true
    setVisibleConfirm(false); // Close the dialog box
    if (rowData) {
      handleDownload(rowData.link, rowData.partner); // Call handleDownload with current row data
    }
  };

  const handleHistoryClick = (rowData) => {
    setSelectedRow(rowData); // Set the selected row data
    setHistoryDialog(true); // Open the history dialog
  };

  const renderHistory = (rowData) => {
    const isApprovedForDownload =
      rowData.partner === "X-Chem"
        ? approvedDownload.xchem && approvedDownload.xchemTerms
        : true; // Other datasets are always accessible

    if (isApprovedForDownload) {
      return (
        <button
          className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100"
          label="View History"
          onClick={() => handleHistoryClick(rowData)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
        </button>
      );
    } else {
      return (
        <>
          <Button
            type="button"
            label="Not Authorized"
            tooltip="You need to apply for access to this dataset.
Once accepted, you will be able to view it."
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
          />
        </>
      );
    }
  };

  const renderDownloadButton = (rowData) => {
    // User is logged in and approved for downloading this dataset
    const isApprovedForDownload =
      rowData.partner === "X-Chem"
        ? approvedDownload.xchem && approvedDownload.xchemTerms
        : true; // Other datasets are always accessible

    if (isApprovedForDownload) {
      // User is approved to download the dataset
      return (
        <>
          <Dialog
            visible={visibleConfirm}
            header="Download Dataset"
            style={{ width: "700px" }}
            onHide={() => setVisibleConfirm(false)}
            footer={
              <div>
                <Button
                  label="No"
                  onClick={() => setVisibleConfirm(false)}
                  className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                />
                <Button
                  className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-green-500 text-base text-white hover:bg-green-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  label="Yes"
                  onClick={() => handleYesClick(selectedRowData)}
                />
              </div>
            }
          >
            {selectedRowData && (
              <p className="m-0">
                {/* By clicking "Yes", you acknowledge and agree that your access to
                and use of this Dataset is subject to the terms and conditions
                of the{" "} */}
                {selectedRowData.partner === "HitGen" ? (
                  <>
                    {
                      " By clicking 'Yes', you acknowledge and agree that your access to and use of this Dataset is subject to the terms and condition of the "
                    }
                    <HitgenPDFLink />
                    {
                      " , including a strict prohibition on reverse engineering the identities of structures or other attributes of the DNA-encoded libraries from which the Dataset is derived"
                    }
                  </>
                ) : selectedRowData.partner === "X-Chem" ? (
                  <>
                    {
                      " By clicking 'Yes', you acknowledge and agree that your access to and use of this Dataset is subject to the terms and condition of the "
                    }
                    <XchemPDFLink />
                    {
                      " , including a strict prohibition on reverse engineering the identities of structures or other attributes of the DNA-encoded libraries from which the Dataset is derived"
                    }
                  </>
                ) : selectedRowData.partner === "External" ? (
                  <>
                    {
                      "By clicking 'Yes,' you agree to be redirected to the dataset hosted on Kaggle. Please note that this dataset is not an asset of the AIRCHECK project, and you must comply with the terms and conditions set by the competition"
                    }
                  </>
                ) : null}
                {/* , including a strict prohibition on reverse engineering the
                identities of structures or other attributes of the DNA-encoded
                libraries from which the Dataset is derived */}
              </p>
            )}
          </Dialog>

          {!isAgreed && (
            <button
              className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100"
              onClick={() => {
                setSelectedRowData(rowData);
                setVisibleConfirm(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                />
              </svg>
            </button>
          )}
        </>
      );
    } else if (currentUser) {
      if (applicationId) {
        // Let the user know still pending approval or denied access
        if (rowData.partner === "X-Chem") {
          if (approvedDownload.xchem === false) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Denied access for ${rowData.partner} datasets`}</span>
            );
          } else if (!approvedDownload.xchemTerms) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Your application has not agreed to ${rowData.partner} EULA`}</span>
            );
          }
        } else if (rowData.partner === "HitGen") {
          if (approvedDownload.hitgen === false) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Denied access for ${rowData.partner} datasets`}</span>
            );
          } else if (!approvedDownload.hitgenTerms) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Your application has not agreed to ${rowData.partner} EULA`}</span>
            );
          }
        }
        return (
          <span className="text-blue-700 text-xs md:text-sm">
            Pending approval
          </span>
        );
      } else {
        // Prompt the user to get dataset access
        return (
          <Link to="/application">
            <button className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100">
              Apply for all partners dataset
            </button>
          </Link>
        );
      }
    } else {
      // User is not logged in, prompt to login or signup
      return (
        <span className="text-red-500 text-xs md:text-sm">
          Please login/signup to access the XChem datasets
          {/* Public access coming soon */}
        </span>
      );
    }
  };

  const rowClass = (data) => {
    if (data.partner === "X-Chem") {
      return "bg-primary-xchem";
    } else if (data.partner === "HitGen") {
      return "bg-primary-hitgen";
    } else {
      return "bg-primary-external";
    }
  };
  return (
    <div>
      <div className="flex justify-end">
        {applicationId && (
          <Link to={`/view-application/${applicationId}`}>
            <button className="mb-2 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300">
              My Application
            </button>
          </Link>
        )}
      </div>
      <DataTable
        className="centered-header"
        value={datasets}
        stripedRows
        paginator
        rows={20}
        sortField="loginRequired"
        sortOrder={1}
        rowClassName={rowClass}
        rowsPerPageOptions={[20, 25, 30, 50]}
      >
        <Column
          field="target"
          header="Target Name"
          style={{ width: "15%" }}
          alignHeader={"center"}
          sortable
        />
        <Column
          field="description"
          header="Description"
          style={{ width: "20%" }}
          alignHeader={"center"}
        />
        <Column
          field="selectionDate"
          header="Selection Date"
          style={{ width: "15%" }}
          alignHeader={"center"}
          sortable
        />
        <Column
          field="partner"
          header="Partner"
          style={{ width: "12.5%" }}
          alignHeader={"center"}
          sortable
        />
        <Column
          body={renderDataDictionaryButton}
          style={{ width: "12.5%" }}
          alignHeader={"center"}
          header="Data Dictionary"
        />
        <Column
          body={renderDownloadButton}
          style={{ width: "15%" }}
          alignHeader={"center"}
          header="Download Dataset"
          field="isDownloadable"
        />
        <Column
          body={renderHistory}
          style={{ width: "15%" }}
          alignHeader={"center"}
          header="Dataset History"
        />
      </DataTable>
      <Dialog
        visible={dictDialogInfo.visible}
        onHide={() => setDictDialogInfo({ visible: false, partner: null })}
        header="Data Dictionary"
      >
        <ul className="bg-gray-200 p-2 sm:p-3 flex items-center text-gray-800 border border-gray-400">
          <li className="w-full text-left font-bold mr-4">Field Name</li>
          <li className="w-full text-left font-bold">Data Type</li>
          <li className="w-full text-left font-bold"> Mode</li>
          <li className="w-full text-left font-bold">Description</li>
        </ul>
        {dictDialogInfo.visible &&
          (() => {
            const partner = dict[dictDialogInfo.partner];
            return (
              partner &&
              Object.entries(partner)
                .filter(([key]) => key !== "id")
                .map(([name, entry]) => renderDictItem({ name, entry }))
            );
          })()}
      </Dialog>
      <VersionDialog
        header="Dataset History"
        visible={historyDialog}
        maximizable
        style={{
          width: "40vw",
        }}
        onHide={() => {
          setHistoryDialog(false);
          setSelectedRowData(null);
        }}
        approvedDownload={approvedDownload}
        rowData={selectedRow}
      />
    </div>
  );
};

const Datasets = () => {
  const [datasets, setDatasets] = useState([]);
  const [dict, setDict] = useState([]);
  const [asms_datasets, setAsmsDatasets] = useState([]);
  const [isloading, setLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const items = [
    { label: "DEL-Datasets", icon: "pi pi-fw pi-database" },
    { label: "ASMS Datasets", icon: "pi pi-fw pi-database" },
  ];
  useEffect(() => {
    // fetch dataset downloads and data dictionaries
    const getDatasets = async () => {
      try {
        setLoading(true);
        const res = await axios.get("/api/data/dataset");
        setDatasets(res.data);
        const asms_res = await axios.get("/api/data/asms_dataset");
        setAsmsDatasets(asms_res.data);
        const res2 = await axios.get("/api/data/dictionary");
        setDict(res2.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data: ", error);
      }
    };
    getDatasets();
  }, []);

  if (isloading) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner
          style={{ width: "250px", height: "250px" }}
          strokeWidth="6"
        />
      </div>
    );
  }

  return (
    <div className="min-h-full md:min-h-screen items-center py-20 px-4 md:px-32">
      <div
        className="relative flex text-orange-700 text-l font-bold px-2 py-2"
        role="alert"
      >
        <svg
          className="fill-current w-12 h-6 mr-2 "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          style={{ fill: "#ff0000" }}
        >
          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
        </svg>

        <p>
          Attention all DEL-ML enthusiasts! Our datasets are currently being
          benchmarked to ensure you get the most accurate and valuable data
          possible. We're just as excited as you are and promise it will be
          worth the wait. Stay connected for the big reveal later this year!
        </p>
      </div>
      <TopBar />
      <div className="bg-transparent text-black mb-1 flex justify-center">
        <TabMenu
          model={items}
          activeIndex={selectedTab}
          onTabChange={(e) => setSelectedTab(e.index)}
        />
      </div>
      {selectedTab === 0 && <Table datasets={datasets} dict={dict} />}
      {selectedTab === 1 && <AsmsTable datasets={asms_datasets} dict={dict} />}
    </div>
  );
};

export default Datasets;
