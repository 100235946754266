import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Button } from "primereact/button";
// import { Panel } from "primereact/panel";
import VersionDialog from "./VersionDetails";

const renderDictItem = ({ name, entry }) => {
  return (
    <ul
      class="p-2 sm:p-3 flex items-center text-gray-800 border border-gray-400"
      key={name}
    >
      <li className="w-full text-left ml-2">{name}</li>
      <li className="w-full text-left">{entry.mode}</li>
      <li className="w-full text-left ">{entry.description}</li>
    </ul>
  );
};

const AsmsTable = ({ datasets, dict }) => {
  const [historyDialog, setHistoryDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [dictDialogInfo, setDictDialogInfo] = useState({
    visible: false,
    partner: null,
  });
  const [applicationId, setApplicationId] = useState(null);
  const [approvedDownload, setApprovedDownload] = useState({
    hitgen: false,
    hitgenTerms: false,
    xchem: false,
    xchemTerms: false,
    preApproved: false,
  });
  const { currentUser, loading } = useAuth();
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [isAgreed, setAgreeTerms] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  // const ref = useRef(null);

  useEffect(() => {
    const approvalCheck = async () => {
      try {
        const res = await axios.get(
          `/api/data/getwhitelisted/${currentUser.email}`
        );
        setApprovedDownload({
          hitgen: res.data.hitgenAccepted,
          hitgenTerms: res.data.hitgenTerms,
          xchem: res.data.xchemAccepted,
          xchemTerms: res.data.xchemTerms,
          preApproved: res.data.preApproved,
        });
      } catch (error) {
        console.error("Error fetching error: ", error);
      }
    };
    const getApplication = async () => {
      try {
        const res = await axios.get(
          `/api/application/email/${currentUser.email}`
        );
        setApplicationId(res.data.applicationId);
      } catch (error) {
        console.error("Error fetching error: ", error);
      }
    };

    if (!loading && currentUser) {
      approvalCheck();
      getApplication();
    }
  }, [currentUser, loading]);

  const handleDownload = async (datasetId, partner) => {
    try {
      setAgreeTerms(false);
      const response = await axios.get(`/api/gcp/getsignedurl`, {
        params: {
          datasetId,
          partner,
        },
      });

      // Use the signed URL for download
      const signedUrl = response.data.signedUrl;
      window.location.assign(signedUrl);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 429) {
          console.error(
            "Too many requests, please try again after 12 AM EST.",
            error.response.data.message
          );
          const message =
            error.response.data.message ||
            "Too many requests, please try again later.";
          alert(message);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        alert("No response from the server. Please try again later.");
      } else {
        alert("Error fetching download link.");
      }
    }
  };

  const renderDataDictionaryButton = (rowData) => {
    return (
      <button
        className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100"
        onClick={() =>
          //   setDictDialogInfo({ visible: true, partner: rowData.partner })
          setDictDialogInfo({ visible: true, partner: "ASMS" })
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
      </button>
    );
  };

  const renderHistory = (rowData) => {
    // const isApprovedForDownload =
    //   (rowData.partner === "HitGen" &&
    //     approvedDownload.hitgen &&
    //     approvedDownload.hitgenTerms) ||
    //   (rowData.partner === "X-Chem" &&
    //     approvedDownload.xchem &&
    //     approvedDownload.xchemTerms) ||
    //   rowData.partner === "External" ||
    //   approvedDownload.preApproved;

    const isApprovedForDownload = true;
    if (isApprovedForDownload) {
      return (
        <button
          className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100"
          label="View History"
          onClick={() => handleHistoryClick(rowData)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
        </button>
      );
    } else {
      return (
        <>
          <Button
            type="button"
            label="Not Authorized"
            tooltip="You need to apply for access to this dataset.
Once accepted, you will be able to view it."
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
          />
        </>
      );
    }
  };
  const handleYesClick = (rowData) => {
    setAgreeTerms(true); // Update isAgreed state to true
    setVisibleConfirm(false); // Close the dialog box
    if (rowData) {
      handleDownload(rowData.link, rowData.partner); // Call handleDownload with current row data
    }
  };
  const handleHistoryClick = (rowData) => {
    setSelectedRow(rowData); // Set the selected row data
    setHistoryDialog(true); // Open the history dialog
  };

  const renderDownloadButton = (rowData) => {
    const isApprovedForDownload = true;
    const currentUser = false;

    if (isApprovedForDownload) {
      // User is approved to download the dataset
      return (
        <>
          <Dialog
            visible={visibleConfirm}
            header="Download Dataset"
            style={{ width: "700px" }}
            onHide={() => setVisibleConfirm(false)}
            footer={
              <div>
                <Button
                  label="No"
                  onClick={() => setVisibleConfirm(false)}
                  className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                />
                <Button
                  className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-green-500 text-base text-white hover:bg-green-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  label="Yes"
                  onClick={() => handleYesClick(selectedRowData)}
                />
              </div>
            }
          >
            {selectedRowData && (
              <p className="m-0">
                By Clicking <strong>Yes</strong>, you agree to use data under
                the terms of the{" "}
                <a
                  href="https://creativecommons.org/licenses/by/4.0/deed.en"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  Creative Commons Attribution 4.0 International License (CC BY
                  4.0)
                </a>{" "}
              </p>
            )}
          </Dialog>

          {!isAgreed && (
            <button
              className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100"
              onClick={() => {
                setSelectedRowData(rowData);
                setVisibleConfirm(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                />
              </svg>
            </button>
          )}
        </>
      );
    } else if (currentUser) {
      if (applicationId) {
        // Let the user know still pending approval or denied access
        if (rowData.partner === "X-Chem") {
          if (approvedDownload.xchem === false) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Denied access for ${rowData.partner} datasets`}</span>
            );
          } else if (!approvedDownload.xchemTerms) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Your application has not agreed to ${rowData.partner} EULA`}</span>
            );
          }
        } else if (rowData.partner === "HitGen") {
          if (approvedDownload.hitgen === false) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Denied access for ${rowData.partner} datasets`}</span>
            );
          } else if (!approvedDownload.hitgenTerms) {
            return (
              <span className="text-red-500 text-xs md:text-sm">{`Your application has not agreed to ${rowData.partner} EULA`}</span>
            );
          }
        }
        return (
          <span className="text-blue-700 text-xs md:text-sm">
            Pending approval
          </span>
        );
      } else {
        // Prompt the user to get dataset access
        return (
          <Link to="/application">
            <button className="text-blue-700 py-2 hover:text-blue-500 hover:scale-105 transition duration-100">
              Apply for ASMS dataset
            </button>
          </Link>
        );
      }
    } else {
      // User is not logged in, prompt to login or signup
      return (
        <span className="text-red-500 text-xs md:text-sm">
          {/* Please login/signup to access the datasets */}
          Public access coming soon
        </span>
      );
    }
  };

  return (
    <div>
      <div className="flex justify-end">
        {applicationId && (
          <Link to={`/view-application/${applicationId}`}>
            <button className="mb-2 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300">
              My Application
            </button>
          </Link>
        )}
      </div>
      <DataTable
        className="centered-header"
        value={datasets}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column
          field="target"
          header="Target Name"
          style={{ width: "10%" }}
          alignHeader={"center"}
          sortable
        />
        <Column
          field="description"
          header="Description"
          style={{ width: "20%" }}
          alignHeader={"center"}
        />
        <Column
          field="selectionDate"
          header="Selection Date"
          style={{ width: "15%" }}
          alignHeader={"center"}
          sortable
        />
        <Column
          field="partner"
          header="Partner"
          style={{ width: "12.5%" }}
          alignHeader={"center"}
          sortable
        />
        <Column
          body={renderDataDictionaryButton}
          style={{ width: "12.5%" }}
          alignHeader={"center"}
          header="Data Dictionary"
        />
        <Column
          body={renderDownloadButton}
          style={{ width: "15%" }}
          alignHeader={"center"}
          header="Download Dataset"
        />
        <Column
          body={renderHistory}
          style={{ width: "15%" }}
          alignHeader={"center"}
          header="Dataset History"
        />
      </DataTable>
      <Dialog
        visible={dictDialogInfo.visible}
        onHide={() => setDictDialogInfo({ visible: false, partner: null })}
        header="Data Dictionary"
      >
        <ul className="bg-gray-200 p-2 sm:p-3 flex items-center text-gray-800 border border-gray-400">
          <li className="w-full text-left font-bold mr-4">Field Name</li>
          <li className="w-full text-left font-bold">Data Type</li>
          <li className="w-full text-left font-bold">Description</li>
        </ul>
        {dictDialogInfo.visible &&
          (() => {
            const partner = dict[dictDialogInfo.partner];
            // const partner = "ASMS";
            return (
              partner &&
              Object.entries(partner)
                .filter(([key]) => key !== "id")
                .map(([name, entry]) => renderDictItem({ name, entry }))
            );
          })()}
      </Dialog>
      <VersionDialog
        header="Dataset History"
        visible={historyDialog}
        maximizable
        style={{
          width: "40vw",
        }}
        onHide={() => {
          setHistoryDialog(false);
          setSelectedRowData(null);
        }}
        approvedDownload={approvedDownload}
        rowData={selectedRow}
      />
    </div>
  );
};

export { AsmsTable };
