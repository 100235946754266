import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { ProgressSpinner } from "primereact/progressspinner";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const [authenticated, setAuthenticated] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    let timer;

    timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer); // Cleanup: Clear the timer if the component unmounts
    };
  }, []);

  useEffect(() => {
    const checkAndSetAuthenticated = async () => {
      if (!currentUser) {
        setDialogVisible(true);
        // setLoading(false);
        return;
      }

      try {
        const res = await axios.get(`/api/data/admin/${currentUser.email}`);

        if (res && (res.data.hitgenAdmin || res.data.xchemAdmin || res.data.superAdmin)) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
          setDialogVisible(true);
        }
      } catch (error) {
        console.error("Error:", error.message);
        if (error.response && error.response.status === 500) {
          console.log("Internal Server Error. Please try again later.");
        }
      } finally {
        setLoading(false); // Regardless of success or failure, set loading to false
      }
    };

    checkAndSetAuthenticated();
  }, [currentUser]);

  const hideDialog = () => {
    setDialogVisible(false);
    navigate("/datasets");
  };

  if (isLoading) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner style={{ width: "250px", height: "250px" }} strokeWidth="6" />
      </div>
    );
  }

  // Render Outlet if authenticated, otherwise navigate to the datasets page

  return authenticated ? (
    <Outlet />
  ) : (
    <div className="card flex justify-content-center h-screen">
      <Dialog
        modal
        header="Authentication Required"
        position={"middle"}
        footer={
          <Button
            className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
            label="Close"
            onClick={hideDialog}
            autoFocus
          />
        }
        style={{ width: "500px" }}
        visible={dialogVisible}
        onHide={hideDialog}
      >
        <p>You are not authorized to view this page. Please login to an authorized account first.</p>
      </Dialog>
    </div>
  );
};

// Restrict route to unauthorized user

const ApplicationRoute = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clean up the timeout
    return () => clearTimeout(timer);
  }, []);

  const hideDialog = () => {
    navigate("/datasets");
  };

  if (loading) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner style={{ width: "250px", height: "250px" }} strokeWidth="6" />
      </div>
    );
  }

  // Render Outlet if authenticated, otherwise navigate to the datasets page

  if (!currentUser) {
    return (
      <div className="card flex justify-content-center h-screen">
        <Dialog
          modal
          style={{ width: "500px" }}
          header="Authentication Required"
          position={"middle"}
          footer={
            <Button
              className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
              label="Close"
              onClick={hideDialog}
              autoFocus
            />
          }
          visible={true} // Always show the dialog if currentUser is null
          onHide={hideDialog}
        >
          <p>You are not authorized to view this page. Please login to an authorized account first.</p>
        </Dialog>
      </div>
    );
  }

  // Render Outlet if authenticated
  return <Outlet />;
};

export { PrivateRoutes, ApplicationRoute };
