import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// // import PageNotFound from "../assets/images/PageNotFound";

const NotFoundPage = () => {
  return (
    <div>
      <header
        className="h-screen w-full bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: "url('/images/backgrounds/404_new.jpeg')" }}
      >
        <div className="flex flex-col justify-center h-full px-4 text-center md:items-start md:text-left md:px-32">
          <h4 className="text-gray-200 text-4xl md:text-8xl font-bold">Page Not found</h4>
          {/* <p className="text-black-200 text-xl md:text-3xl mt-4">Page Not found</p> */}
          <Link to="/">
            <button className="mt-6 bg-blue-700 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300">
              Home
            </button>
          </Link>
        </div>
      </header>
    </div>
  );
};
export default NotFoundPage;
