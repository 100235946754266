import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { HitgenPDFLink, XchemPDFLink } from "../terms_and_conditions/ReadPdf";
import Datasets from "./datatable/DisplayTarget";
import { CustomDialog } from "../../components/common/dialog/CustomDialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

const addToOrganization = async (formData) => {
  const response = await axios.post(
    "/api/application/add_to_organization",
    formData
  );
  return {
    status: response.status,
    data: response.data,
  };
};
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const addGenericSubmission = async (formData) => {
  // await delay(3000);
  const response = await axios.post("/api/application/submit", formData);
  return {
    status: response.status,
    data: response.data,
  };
};

const MultiApplicants = ({ finalOrgList, finalUniList, countriesName }) => {
  const { currentUser } = useAuth();

  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");
  const [individualApplication, setIndividualApplication] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [businessLine, setBusinessLine] = useState("");
  const [useDescription, setUseDescription] = useState("");
  const [openedEULAXchem, setOpenedEULAXchem] = useState(false);
  const [openedEULAHitgen, setOpenedEULAHitgen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [currentRole, setCurrentRole] = useState("");

  const [dialogVisible, setDialogVisible] = useState(false);
  const [academicApplication, setAcademicApplication] = useState(false);

  const [users, setUsers] = useState([
    { firstName: "", lastName: "", email: "", role: "" },
  ]);
  const [hitgenAccepted, setHitgenAccepted] = useState(false);
  const [xchemAccepted, setXchemAccepted] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const [selectedUniversity, setSelectedUniversity] = useState(null);

  useEffect(() => {
    if (selectedOrg && selectedOrg?.name !== "Create New") {
      setCountryOptions([{ name: selectedOrg.organizationCountry }]);
      setSelectedCountry({ name: selectedOrg.organizationCountry });
    } else if (
      selectedUniversity &&
      selectedUniversity?.name !== "Create New"
    ) {
      setCountryOptions([{ name: selectedUniversity.organizationCountry }]);
      setSelectedCountry({ name: selectedUniversity.organizationCountry });
    } else {
      // If "Create New" is selected or no organization is selected, use the full country list
      setCountryOptions(countriesName?.data || []);
      setSelectedCountry(null);
      setBusinessLine("");
      setOrgAddress("");
    }
  }, [selectedOrg, selectedUniversity, countriesName.data]);

  const selectedOrgTemplate = (option, props) => {
    if (option) {
      return (
        <div className="blue-950 flex align-items-center">
          <div>
            <span style={{ color: "#e57a0e", fontWeight: "20px" }}>
              {option.name}
            </span>
          </div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const orgOptionTemplate = (option) => {
    return (
      <div className="text-l font-bold flex align-items-center">
        <div>
          <span style={{ color: "#0D3F29" }}>{option.name}</span>
        </div>
      </div>
    );
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const handleUserChange = (index, field, value) => {
    const updatedusers = [...users];
    updatedusers[index][field] = value;

    setUsers(updatedusers);
  };

  const addUser = () => {
    setUsers([...users, { firstName: "", lastName: "", email: "", role: "" }]);
  };

  const removeUser = (index) => {
    setUsers(users.filter((_, i) => i !== index));
  };

  const checkWithSubmitterEmail = (users, submitterEmail) => {
    if (!users || !Array.isArray(users) || !submitterEmail) {
      throw new Error(
        "Invalid arguments: users must be an array, and submitterEmail is required."
      );
    }
    submitterEmail = submitterEmail.toLowerCase();

    return users.some((user) => {
      const email = user.email?.toLowerCase();

      return email === submitterEmail;
    });
  };

  function checkDuplicateEmails(users) {
    const emailCount = {};

    // Count the occurrences of each email
    users.forEach((user) => {
      const email = user.email.toLowerCase();

      emailCount[email] = (emailCount[email] || 0) + 1;
    });

    // Check if any email appears more than once
    for (const email in emailCount) {
      if (emailCount[email] > 1) {
        return true; // At least 2 users have the same email
      }
    }

    return false; // No duplicate emails found
  }

  const areFieldsEmpty = () => {
    if (
      !orgName ||
      !adminFirstName ||
      !adminLastName ||
      !selectedCountry?.name ||
      !orgAddress ||
      !businessLine ||
      (!hitgenAccepted && !xchemAccepted)
    ) {
      return true;
    }
    if (selectedOrg?.name === "Create New" && !useDescription) {
      return true;
    }

    if (
      selectedOrg?.name !== "Create New" &&
      !academicApplication &&
      !currentRole
    ) {
      return true;
    }

    // Check if any user has empty details
    for (const user of users) {
      if (!user.firstName || !user.lastName || !user.email || !user.role) {
        return true;
      }
    }

    return false;
  };

  // check if new user is associated to existing domain name
  const checkEmailDomain = (selectedOrg) => {
    const emailDomain = selectedOrg.emailDomain;
    return emailDomain;
  };
  const organizationMutation = useMutation({
    mutationFn: addToOrganization,
    onSuccess: (response) => {
      const { status, data } = response;
      if (status === 200) {
        Swal.fire({
          title: "Success!",
          text: data,
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/datasets"; // Navigate to the home page
          }
        });
      } else {
        setDialogVisible({
          visible: true,
          message: `${data}`,
          header: "Error",
        });
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const genericMutation = useMutation({
    mutationFn: addGenericSubmission,
    onSuccess: (response) => {
      const { status, data } = response;
      if (status === 200) {
        Swal.fire({
          title: "Success!",
          text: data,
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/datasets"; // Navigate to the home page
          }
        });
      } else {
        setDialogVisible({
          visible: true,
          message: `${data}`,
          header: "Error",
        });
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });
  const { isPending: isOrgPending } = organizationMutation;
  const { isPending: isGenericPending } = genericMutation;

  const isPending = isOrgPending || isGenericPending;

  // Combine error states
  const isError = organizationMutation.isError || genericMutation.isError;
  const error = organizationMutation.error || genericMutation.error;

  useEffect(() => {
    if (isError && error) {
      handleError(error);
    }
  }, [isError, error]);

  const handleSubmit = async () => {
    // Convert all user emails to lowercase
    users.forEach((user) => {
      user.email = user.email.toLowerCase();
    });
    const isAcademicApplication = academicApplication ? true : false;

    let orgCountry = selectedCountry.name;
    if (orgCountry === "United States") {
      orgCountry = "United States of America";
    }
    const organizationType = isAcademicApplication ? "Academic" : "Corporation";

    // const emailDomain = "gmail.com";
    const emailDomain = currentUser.email.split("@")[1];

    const handleOrganizationSubmission = async () => {
      const domainName = checkEmailDomain(selectedOrg);

      if (emailDomain !== domainName) {
        setDialogVisible({
          visible: true,
          message:
            "To proceed, please log in with the email address provided by your organization.",
          header: "Organizational Email Required",
        });
        return;
      }

      const formData = {
        application_user: {
          newUser: {
            firstName: adminFirstName,
            lastName: adminLastName,
            email: currentUser.email,
            role: currentRole,
          },
          orgNameToMatch: {
            orgName,
          },
        },
      };
      organizationMutation.mutate(formData);
    };

    // Function to handle generic form submission
    const handleGenericSubmission = async () => {
      const formData = {
        application: {
          submitterEmail: currentUser.email,

          submittedInfo: {
            adminFirstName,
            adminLastName,
            orgName,
            orgCountry,
            orgAddress,
            organizationType,
            businessLine,
            useDescription,
          },
          individualApplication,
          users,
          decision: {
            hitgenAccepted: null,
            xchemAccepted: null,
            hitgenDecisionDate: null,
            xchemDecisionDate: null,
          },
          termsAndConditions: {
            hitgenAccepted,
            xchemAccepted,
          },
        },
        admin: {
          email: currentUser.email,
          firstName: adminFirstName,
          lastName: adminLastName,
          role: "Admin",
        },
        isAcademicApplication: {
          isAcademicApplication,
        },
      };

      if (
        checkWithSubmitterEmail(formData.application.users, currentUser.email)
      ) {
        setDialogVisible({
          visible: true,
          message: `${currentUser.email} is already a part of the application, please provide a different email address`,
          header: "Duplicate Email",
        });
        return;
      }

      if (checkDuplicateEmails(formData.application.users)) {
        setDialogVisible({
          visible: true,
          message:
            "Please verify provided email, a different email address is used for each user",
          header: "Duplicate Email",
        });
        return;
      }
      genericMutation.mutate(formData);
    };

    // Main logic
    if (
      selectedOrg &&
      !academicApplication &&
      selectedOrg.name !== "Create New"
    ) {
      await handleOrganizationSubmission();
    } else {
      await handleGenericSubmission();
    }
  };
  const handleError = (error) => {
    if (error.response) {
      setDialogVisible({
        visible: true,
        message: `${error.response.data}`,
        header: "Error",
      });
    } else if (error.request) {
      console.log("Error request:", error.request);
    } else {
      console.log("Error message:", error.message);
    }
    console.error("Error during form submission:", error);
  };

  if (isPending) {
    return (
      <div className="py-4 md:py-28 px-4 md:px-32 w-4/5 mx-auto">
        <div className="fixed inset-0 h-screen justify-center bg-white z-15">
          <div className="flex items-center justify-center">
            <ProgressSpinner
              style={{ margin: "100px", width: "250px", height: "250px" }}
              strokeWidth="6"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {dialogVisible.visible && (
        <CustomDialog
          dialogVisible={dialogVisible.visible}
          setDialogVisible={setDialogVisible}
          message={dialogVisible.message}
          header={dialogVisible.header}
        />
      )}

      <p className="text-sm font-bold text-gray-700 mb-2">
        Fields marked with
        <span className="after:content-['*'] after:ml-1 after:text-red-500 font-bold" />{" "}
        are mandatory
      </p>
      <div className="flex flex-row justify-center mb-4">
        <div className="">
          <input
            type="checkbox"
            onChange={() => {
              if (!academicApplication) {
                setDialogVisible({
                  visible: true,
                  message: [
                    "*Application Submission*: The Principal Investigator (PI) from your lab must be the one to apply for data access. Applications from individuals other than the PI will not be considered.",
                    "*Proposal Submission*: The PI should submit a brief, non-confidential, high-level description of the machine learning algorithm(s) or other research approach(es) that they propose to employ in analyzing the Datasets in sufficient detail to enable a person skilled in the relevant field to readily understand the User’s proposed use(s).",
                    "*Access for Team Members*: Provide the names, email addresses, and titles/roles for the members of your research requiring access. Once approved, those individuals will be able to view and download the Datasets by logging in to AIRCHECK. If you are the proposed “User” as an individual person, do not complete this section as only you will be provided access if approved.",
                    "*Approval Process*: The AIRCHECK team will review the proposal. If it aligns with our guidelines and objectives, access will be granted to the PI and any additional team members specified in the proposal.",
                    "*Data Usage*: All users must adhere to the terms and conditions specified by AIRCHECK. Proper data management and ethical usage are mandatory.",
                  ],
                  header:
                    "Welcome to AIRCHECK! Follow these steps to access DEL-ML data for your research",
                });
              }

              setUsers([]);
              setAcademicApplication(!academicApplication);
              setOrgAddress("");
              setSelectedCountry("");
              setOrgName("");
              setBusinessLine("");
              setCurrentRole("");
              setUseDescription("");
              setSelectedOrg(null);
              setIndividualApplication(false);
              setSelectedCountry(null);
              setSelectedUniversity(null);
            }}
            checked={academicApplication}
          />
        </div>
        <div className="mx-3">
          <label className="text-gray-700 text-sm">
            <p>
              Please click here if you are requesting access to the Datasets{" "}
              <b className="font-extrabold text-base">
                for academic research (for example, as the Principal
                Investigator in an academic laboratory; or the organizer of a
                DEL-ML workshop)
              </b>{" "}
              and not registering on behalf of{" "}
              <b className="font-extrabold text-base">
                a for profit organization (for example, a for-profit pharma
                company).
              </b>{" "}
              Please note that if you check this box, you directly undertake the
              responsibilities of the “User” under the End User License
              Agreements below and no rights to receive or use the Datasets will
              be granted to any organizations or colleagues with whom you may
              work.
            </p>
          </label>
        </div>
      </div>
      {!academicApplication && (
        <div className="mb-4">
          <label htmlFor="orgName" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              Select Your Corporation/Organization
            </span>
            <p className="text-xs text-gray-500">
              Please select your organization from the dropdown list if it is
              already available. By doing so, you agree to abide by the terms
              set by your organization's administrator. If your organization is
              not listed, you can create a new one, and you will become the
              administrator responsible for managing the organization's
              agreements
            </p>
          </label>
          <Dropdown
            value={selectedOrg}
            onChange={(e) => {
              setSelectedOrg(e.value);
              if (e.value.name !== "Create New") {
                setOrgName(e.value.name);
                setSelectedCountry(e.value.organizationCountry);
                setOrgAddress(e.value.address || "");
                setBusinessLine(e.value.businessLine || "");
                setIndividualApplication(true);
                setUsers([]);
              } else {
                setOrgName("");
                setSelectedCountry(null);
                setOrgAddress(""); // Clear the address for new organizations
                setBusinessLine("");
                setIndividualApplication(false);
              }
            }}
            options={finalOrgList}
            optionLabel="name"
            placeholder="Select Organization"
            id="orgName"
            valueTemplate={selectedOrgTemplate}
            itemTemplate={orgOptionTemplate}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      )}
      {selectedOrg && selectedOrg.name === "Create New" ? (
        <div className="mb-4">
          <label htmlFor="orgName" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              Legal Name of Proposed Corporation/Organization
            </span>
            <p className="text-xs text-gray-500">
              If you are proposing to obtain access to the Datasets for use by
              your organization, please provide the organization’s legal name,
              which will be the proposed “User” of the Datasets for purposes of
              the End User License Agreements below.
            </p>
          </label>
          <InputText
            onChange={(e) => setOrgName(e.target.value)}
            id="orgName"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      ) : null}

      <div className="mb-4">
        <label
          htmlFor="adminFirstName"
          className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
        >
          {individualApplication
            ? "Your First Name"
            : "Administrator First Name"}
        </label>
        <InputText
          value={adminFirstName}
          onChange={(e) => setAdminFirstName(e.target.value)}
          id="adminFirstName"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="adminLastName"
          className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
        >
          {individualApplication ? "Your Last Name" : "Administrator Last Name"}
        </label>
        <InputText
          value={adminLastName}
          onChange={(e) => setAdminLastName(e.target.value)}
          id="adminLastName"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      {individualApplication && (
        <div className="mb-4">
          <label htmlFor="currentRole" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              Current Role
            </span>
            <p className="text-xs text-gray-500">
              provide a description of your current role(s) and qualifications
            </p>
          </label>
          <InputText
            value={currentRole}
            onChange={(e) => setCurrentRole(e.target.value)}
            id="currentRole"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      )}

      {academicApplication && (
        <>
          <div className="mb-4">
            <label htmlFor="affiliationID" className="block text-gray-700 mb-2">
              <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
                Select Organization
              </span>
              <p className="text-xs text-gray-500">
                Please select affiliated organization
              </p>
            </label>
            <Dropdown
              value={selectedUniversity}
              onChange={(e) => {
                setSelectedUniversity(e.value);
                // setOrgName(e.value);
                if (e.value.name !== "Create New") {
                  setSelectedCountry(e.value.organizationCountry);
                  setOrgAddress(e.value.address || "");
                  // setBusinessLine(e.value.businessLine || "");
                  setOrgName(e.value.name);
                } else {
                  setOrgName("");
                  setSelectedCountry(null);
                  setOrgAddress("");
                  setBusinessLine("");
                }
              }}
              // options={universities}
              options={finalUniList}
              optionLabel="name"
              placeholder="Select a Organization"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {selectedUniversity && selectedUniversity?.name === "Create New" && (
            <div className="mb-4">
              <label
                htmlFor="affiliationID"
                className="block text-gray-700 mb-2"
              >
                <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
                  Name of Organization
                </span>
              </label>
              <InputText
                value={orgName}
                // onChange={(e) => setNewUniversity(e.target.value)}
                onChange={(e) => setOrgName(e.target.value)}
                id="affiliationID1"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          )}
        </>
      )}

      <div className="mb-4">
        <label htmlFor="orgAdd" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            {academicApplication
              ? "Country"
              : "Country of Your Corporation/Organization"}
          </span>
        </label>

        <Dropdown
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.value)}
          options={countryOptions}
          optionLabel="name"
          placeholder="Select Country"
          id="orgAdd"
          filter
          valueTemplate={selectedCountryTemplate}
          itemTemplate={countryOptionTemplate}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={
            (selectedOrg && selectedOrg?.name !== "Create New") ||
            (selectedUniversity && selectedUniversity?.name !== "Create New")
          }
        />
      </div>

      <div className="mb-4">
        <label htmlFor="orgAddress" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            {academicApplication
              ? "Address"
              : "Address of Corporation/Organization"}
          </span>
          <p className="text-xs text-gray-500">
            {academicApplication
              ? "Please provide the legal address for the proposed Organization"
              : "Please provide the legal address for the proposed Corporation/Organization"}
          </p>
        </label>
        <InputText
          value={orgAddress}
          onChange={(e) => setOrgAddress(e.target.value)}
          id="orgAddress"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={
            (selectedOrg && selectedOrg?.name !== "Create New") ||
            (selectedUniversity && selectedUniversity?.name !== "Create New")
          }
        />
      </div>

      <div className="mb-4">
        <label htmlFor="businessLine" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            Corporation/Organization Line(s) of Business
          </span>
          <p className="text-xs text-gray-500">
            Provide department name of affiliated organization.
          </p>
        </label>
        <InputText
          value={businessLine}
          onChange={(e) => setBusinessLine(e.target.value)}
          id="businessLine"
          disabled={selectedOrg && selectedOrg?.name !== "Create New"}
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      {!individualApplication && (
        <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4 border-gray-100 border-2">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
                “User” Corporation/Organization Employees Requiring Access
              </span>
              <p className="text-xs text-gray-500">
                Please provide the names, email addresses, and titles/roles for
                the employees in your organization requiring access. Once
                approved, those individuals will be able to view and download
                the Datasets by logging in to AIRCHECK. If you are the proposed
                “User” as an individual person, do not complete this section as
                only you will be provided access if approved.
              </p>
            </label>
            {users?.map((user, index) => (
              <div key={index} className="md:grid md:grid-cols-5 md:gap-3">
                <div className="relative z-0 group">
                  <InputText
                    value={user.firstName}
                    type="text"
                    placeholder=" "
                    onChange={(e) =>
                      handleUserChange(index, "firstName", e.target.value)
                    }
                    id={`floating_fname_${index}`}
                    className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor={`floating_fname_${index}`}
                    className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    First Name
                  </label>
                </div>

                <div className="relative z-0 group">
                  <InputText
                    value={user.lastName}
                    type="text"
                    onChange={(e) =>
                      handleUserChange(index, "lastName", e.target.value)
                    }
                    placeholder=""
                    className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor={`floating_fname_${index}`}
                    className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Last Name
                  </label>
                </div>
                <div className="relative z-0 group">
                  <InputText
                    value={user.email}
                    type="email"
                    onChange={(e) =>
                      handleUserChange(index, "email", e.target.value)
                    }
                    placeholder=""
                    className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor={`floating_fname_${index}`}
                    typeof="email"
                    className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Email
                  </label>
                  <p className="invisible peer-invalid:visible text-pink-400 text-sm">
                    Please provide a valid email address.
                  </p>
                </div>
                <div className="relative z-0 group">
                  <InputText
                    value={user.role}
                    type="text"
                    onChange={(e) =>
                      handleUserChange(index, "role", e.target.value)
                    }
                    placeholder=""
                    className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  />
                  <label
                    htmlFor={`floating_fname_${index}`}
                    className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Role
                  </label>
                </div>

                <Button
                  className="text-red-600 w-8"
                  onClick={() => removeUser(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                    />
                  </svg>
                </Button>
              </div>
            ))}
            <Button className="text-green-600 w-6" onClick={addUser}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                />
              </svg>
            </Button>
          </div>
        </div>
      )}

      {!individualApplication && (
        <div className="mb-4">
          <label htmlFor="useDescription" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              “Proposed Use” of Datasets
            </span>
            <p className="text-xs text-gray-500">
              Please provide a brief, non-confidential, high-level description
              of the machine learning algorithm(s) or other research
              approach(es) that the User proposes to employ in analyzing the
              Datasets in sufficient detail to enable a person skilled in the
              relevant field to readily understand the User’s proposed use(s).
            </p>
          </label>
          <InputTextarea
            value={useDescription}
            onChange={(e) => setUseDescription(e.target.value)}
            id="useDescription"
            rows={6}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <p className="text-xs text-gray-700 before:content-['*'] before:mr-1">
            <span className="underline underline-offset-1 font-bold">
              For X-Chem Datasets only
            </span>
            : please note that the Proposed Use provided here, if accepted, will
            govern the scope of the authorized use(s) of the X-Chem Datasets
            under the End User License Agreement below, and such description
            must be updated if the User proposes to make uses of the Datasets
            not encompassed by the original description provided.
          </p>
        </div>
      )}

      <div className="mb-8">
        <h1 className="font-extrabold text-gray-700"> HitGen Datasets</h1>
        <div className="flex flex-row justify-center mb-4">
          <div className="hitgen-tooltip">
            <input
              type="checkbox"
              onChange={() => setHitgenAccepted(!hitgenAccepted)}
              checked={hitgenAccepted}
              disabled={!openedEULAHitgen}
              className="disabled:opacity-50 disabled:cursor-not-allowed"
            />
            {!openedEULAHitgen && (
              <Tooltip
                target=".hitgen-tooltip"
                content="Review HitGen EULA before accepting"
                position="left"
              />
            )}
          </div>
          <div className="ml-3">
            <label className="text-gray-700 text-sm">
              <p>
                By clicking here, you hereby acknowledge having read and
                understood the{" "}
                <span onClick={() => setOpenedEULAHitgen(true)}>
                  <HitgenPDFLink />
                </span>{" "}
                and you hereby agree on behalf of the User that, if your
                registration is accepted, the User shall be bound by all of the
                terms and conditions thereof.
              </p>
            </label>
          </div>
        </div>
        <h1 className="font-extrabold text-gray-700 ml-1">X-Chem Datasets</h1>
        <div className="flex flex-row justify-center">
          <div className="xchem-tooltip">
            <input
              type="checkbox"
              onChange={() => setXchemAccepted(!xchemAccepted)}
              checked={xchemAccepted}
              disabled={!openedEULAXchem}
              className="disabled:opacity-50 disabled:cursor-not-allowed"
            />
            {!openedEULAXchem && (
              <Tooltip
                target=".xchem-tooltip"
                content="Review X-Chem EULA before accepting"
                position="left"
              />
            )}
          </div>
          <div className="ml-3">
            <label className="text-gray-700 text-sm">
              <p>
                By clicking here, you hereby acknowledge having read and
                understood the{" "}
                <span onClick={() => setOpenedEULAXchem(true)}>
                  <XchemPDFLink />
                </span>{" "}
                and you hereby agree on behalf of the User that, if your
                registration is accepted, the User shall be bound by all of the
                terms and conditions thereof.
              </p>
            </label>
          </div>
        </div>
      </div>

      <div className="flex-row">
        <Datasets hitgen={hitgenAccepted} xchem={xchemAccepted} />
      </div>

      <div className="flex items-center justify-center">
        <div className="tooltip-wrapper">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={areFieldsEmpty() || !currentUser}
            onClick={handleSubmit}
          >
            Submit
          </button>
          {(areFieldsEmpty() || !currentUser) && (
            <Tooltip
              target=".tooltip-wrapper"
              content="Verify you've completed all fields and you're logged in"
              position="top"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MultiApplicants;
